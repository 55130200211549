import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The resort at the border of Austria and Italy is truly a gem for all enduro
fans.`}</p>
    <br />
    <p>{`The lifts open up a wide area around the 1500m high Reschenpass with
it’s two beautiful lakes. On both sides of the valley great natural trails
have been created. They are challenging, but have an awesome rhythm over the
many roots, rocks and grippy forest floor.`}</p>
    <br />
    <p>{`The combination of lift access and pedaling even brings you to the border
triangular of Italy-Switzerland-Austria with stunning views over the Reschenpass
and the lakes. The best time due to its altitude is from June to September.`}</p>
    <TrailguidePlugin content="lat=46.8260&lng=10.5192&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,46.8260,10.5192"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Spin Trail`}</h4>
    <p>{`Roots, rocks and good speed.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1954"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Haideralm Trail`}</h4>
    <p>{`Our favourite one. The lakes at the pass look through the trees.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1938"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Haideralm Trail`}</h4>
    <p>{`So many roots and cool corners.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1938"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Haideralm Trail`}</h4>
    <p>{`Some natural jumps here and there.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1938"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Spin trail`}</h4>
    <p>{`Also on the other trails there are often natural opportunities to catch some air.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1954"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_2.jpg" mdxType="Image" />
    <br />
    <h4>{`3-Länder Trail`}</h4>
    <p>{`On the way up to the 3-Länder Trail at the border triangular of Switzerland, Austria and Italy, you shouldn't miss the Reschner Alm.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1937"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_9.jpg" mdxType="Image" />
    <br />
    <h4>{`3-Länder Trail`}</h4>
    <p>{`At the border triangular a long descent starts down to Nauders.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1937"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_10.jpg" mdxType="Image" />
    <br />
    <h4>{`3-Länder Trail`}</h4>
    <p>{`The trail passes some idyllic places.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1937"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_11.jpg" mdxType="Image" />
    <br />
    <h4>{`Schöneben Trail`}</h4>
    <p>{`Similar to the Haideralm Trail, with many roots and grippy forest floor. One of our favourites.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1936"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/reschen_7.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      